<template>
    <div class="row">
        <div class="col-xl-12">
            <div id="panel-1" class="panel">
                <div class="panel-container show">
                    <div class="panel-content">
                        <el-row :gutter="10" class="mb-5">
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                <el-input type="text" clearable size="medium" v-model="form.id" class=" mt-3"
                                          placeholder="Mã nhân sự" autocomplete="false" ></el-input>
                            </el-col>
                            <el-col v-if="isHCKV" :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                              <el-select clearable v-model="form.branch_id"  filterable class="w-100 mt-3"
                                         placeholder="Chọn văn phòng để xem" >
                                <el-option
                                    v-for="item in branches"
                                    :key="item.id"
                                    :label="item.name_alias ? item.name_alias : item.name"
                                    placeholder="Chọn cơ sở"
                                    :value="item.id"
                                >
                                  <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                                </el-option>
                              </el-select>
                            </el-col>
                            <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="3">
                                <el-date-picker
                                        class="w-100"
                                        v-model="form.startDate"
                                        type="date"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        @change="pickStart"
                                        :picker-options="pickerStartOptions"
                                        placeholder="Từ ngày">
                                </el-date-picker>
                            </el-col>
                            <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="3">
                                <el-date-picker
                                        class="w-100"
                                        v-model="form.endDate"
                                        type="date"
                                        ref="picker"
                                        :onPick="pickEnd"
                                        format="yyyy-MM-dd"
                                        @change="pickEnd"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="pickerEndOptions"
                                        placeholder="Đến ngày">
                                </el-date-picker>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                                <button :disabled="loading.buttonSearch"
                                        class="btn btn-primary w-100 mt-3"
                                        @click="querySever">
                                    <i v-bind:class="[loading.buttonSearch ? 'el-icon-loading' : 'el-icon-search']"></i>
                                    Tìm kiếm
                                </button>
                            </el-col>
                        </el-row>
                        <!-- datatable start -->
                        <el-table
                                :data="result.tableData"
                                stripe
                                v-loading="loading.table"
                                border
                                tableSize=" table-bordered table-striped table-hover"
                                class="table-hover"
                                style="width: 100%">
                            <el-table-column label="#" type="index"  width="50" :index="indexMethod">
                            </el-table-column>
                            <el-table-column
                                    prop="id"
                                    width="100"
                                    label="Mã nhân sự">
                              <template v-slot="scope">
                                {{scope.row.user.id + 100000}}
                              </template>
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    label="Họ và tên">
                              <template v-slot="scope">
                                {{scope.row.user.name}}
                              </template>
                            </el-table-column>
                            <el-table-column label="Số ca đã đăng ký đi làm" >
                                <template v-slot="scope">
                                    <i class="el-icon-money"></i>
                                    <span style="margin-left: 10px">{{ renderTotalRegister(scope.row) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="total_attendance"
                                    label="Tổng công"
                            >
                              <template v-slot="scope">
                                <span style="margin-left: 10px">{{ renderTotalTimeKeep(scope.row) }}</span>
                              </template>
                            </el-table-column>
                          <el-table-column label="Văn phòng" v-if="isHCKV">
                              <template v-slot="scope">
                                <span style="margin-left: 10px">{{ scope.row.branch.name_alias ? scope.row.branch.name_alias : scope.row.branch.name }}</span>
                              </template>
                            </el-table-column>
                        </el-table>
                        <div class="edutalk-paging" >
                            <div class="block" v-if="paging.total > 25">
                                <!--eslint-disable-->
                                <el-pagination
                                        background
                                        @current-change="handleCurrentChange"
                                        :current-page.sync="paging.current_page"
                                        :page-size="25"
                                        layout="prev, pager, next"
                                        :total="paging.total">
                                </el-pagination>
                            </div>
                        </div>
                        <!-- datatable end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {TIME_KEEP_TOTAL} from "@/core/services/store/service.module";
    import { HANH_CHINH_KHU_VUC, GDKV } from "@/core/config/accountTypeOption";
    import {BRANCH_LIST} from "@/core/services/store/service.module";
    import {mapGetters} from "vuex";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);
    export default {
        name: "AttendanceView",
        data() {
            return {
                pickerStartOptions: {
                    disabledDate: this.disabledStartDate
                },
                pickerEndOptions: {
                    disabledDate: this.disabledEndDate
                },
                loading: {
                    table: false,
                    buttonSearch: false
                },
                form: {
                    name: null,
                    startDate: null,
                    endDate: null,
                    branch_id: null,
                },
                result: {
                    tableData: null,
                },
                paging: {
                    current_page: 1,
                    per_page: 50,
                    total: 0,
                },
                condition: {
                    isDirector : false
                },
                fromDate: null,
                toDate: null,
                HANH_CHINH_KHU_VUC,
                GDKV,
                branches: [],
                isHCKV: false,
            }
        },
        created() {
          if (this.currentUser.profile.account_type.id === this.HANH_CHINH_KHU_VUC || this.currentUser.profile.account_type.id === this.GDKV){
            this.isHCKV = true;
            this.getBranches();
          }
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
              {title: "Xem chấm công nhân sự", icon: 'far fa-calendar-alt'}
            ]);
            let date = new Date();
            this.form.startDate = this.$moment().startOf('month').format('YYYY-MM-DD');
            this.form.endDate = this.$moment().format('YYYY-MM-DD');
            this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
            this.toDate = new Date();
            this.querySever()

          },

        computed: {
          ...mapGetters(['currentUser'])
        },

        methods: {
            pickStart(date) {
                this.fromDate = null;
                if (date) {
                    this.fromDate = new Date(date);
                }
            },
            disabledStartDate(date) {
                if (this.toDate) {
                    return this.toDate < date
                }
                return date > new Date();
            },
            pickEnd(date) {
                this.toDate = null;
                if (date) {
                    this.toDate = new Date(date);
                }
            },
            disabledEndDate(date) {
                // if (this.form.endDate) {
                //     return this.fromDate > date || date > new Date();
                // }
                // return date > new Date();
            },
            indexMethod(index) {
                return 25 * (this.currentPage - 1) + (index + 1);
            },
            handleChangeBranch () {

            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.querySever({page: val});
            },
            search() {

            },
            querySever() {
                this.loading.table = true;
                this.loading.buttonSearch = true;
                let params = this.mergeParams();
                this.currentPage = 1;
                this.$store.dispatch(TIME_KEEP_TOTAL, params)
                    .then((response) => {
                        if (response.status === 422) {
                            this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                        } else {
                            this.result.tableData = response.data.data;
                            this.paging.total = response.data.total;
                            this.paging.per_page = response.data.per_page;
                            this.paging.current_page = response.data.current_page;
                        }
                        this.loading.table = false;
                        this.loading.buttonSearch = false;
                    })
            },
            mergeParams(customProperties) {
                let params = {
                    page: this.paging.current_page,
                    per_page: this.paging.per_page,
                };
                if (this.form.startDate) {
                    params = _.merge(params, {start_date: this.form.startDate})
                }
                if (this.form.endDate) {
                    params = _.merge(params, {end_date: this.form.endDate })
                }
                if (this.form.id) {
                    params = _.merge(params, {id: this.form.id})
                }
                if (this.form.branch_id) {
                    params = _.merge(params, {branch_id: this.form.branch_id})
                }
                params = _.merge(params, customProperties);
                return params;
            },
             getBranches() {
              this.$store.dispatch(BRANCH_LIST)
                  .then((response) => {
                    if (response.status === 422) {
                      this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                    } else {
                      this.branches = response.data;
                      // this.form.branch_id = response.data.length > 0 ? response.data[0].id : 0;
                    }
                  })
            },
            renderTotalTimeKeep(item) {
              return  parseInt(item.morning_time_keep) + parseInt(item.afternoon_time_keep) + parseInt(item.evening_time_keep);
            },
            renderTotalRegister(item) {
               return  parseInt(item.register_morning) + parseInt(item.register_afternoon) + parseInt(item.register_evening);
            },
        },
    };

</script>
